// Phylocanvas.gl (https://phylocanvas.gl)
// Centre for Genomic Pathogen Surveillance.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

import memoise from "../../utils/memoise";

const internalLabelsMemo = memoise(
  (tree) => tree.getGraphAfterLayout(),
  (tree) => tree.props.internalLabelsFilterRange,
  (
    graph,
    internalLabelsFilterRange,
  ) => {
    const labels = [];

    for (let i = graph.firstIndex; i < graph.lastIndex; i++) {
      const node = graph.preorderTraversal[i];

      if (
        !node.isLeaf
        &&
        !node.isHidden
        &&
        node.name
      ) {
        if (internalLabelsFilterRange) {
          const min = parseFloat(internalLabelsFilterRange[0]);
          const max = parseFloat(internalLabelsFilterRange[1]);
          const value = parseFloat(node.name);
          if (
            value >= min
            &&
            value <= max
          ) {
            labels.push(node);
          }
        }
        else {
          labels.push(node);
        }
      }

      // skip collapsed sub-trees
      if (node.isCollapsed) {
        i += node.totalNodes - 1;
      }
    }
    return labels;
  }
);

export default internalLabelsMemo;
